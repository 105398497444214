@import url('https://fonts.googleapis.com/css2?family=Arsenal:wght@400;700&display=swap');

@import url('https://fonts.cdnfonts.com/css/calmetta-trial');

body{
  
  padding: 57px 81px;
  box-sizing: border-box;
  margin: 0;
  font-size: 18px;
  @media (max-width: 576px) {
    padding: 57px 36px 57px 36px;
  
  }
}